import './App.css';
import maxos from './img/maxos.jpg';
import itd from './img/itd.webp';
import vpn from './img/vpn.webp';
import cv from './sources/CV_alvinscherdin.se.pdf';
import twitter from './img/twitter.webp';
import cvIcon from './img/cv.webp';
import mail from './img/mail.webp';
import alvin from './img/alvin.webp';

import { Helmet } from "react-helmet";

function App() {
  return (
    <div class="padding">
      <Helmet>
        <title>Alvin Scherdin - Entreprenör, SEO expert, webbutvecklare</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Alvin Scherdin",
            "affiliation": [
              {
                "@type": "Organization",
                "name": "Dofterian"
              },
              {
                "@type": "Organization",
                "name": "Tryggt"
              },
              {
                "@type": "Organization",
                "name": "Scherdin Media"
              }
            ],
            "jobTitle": [
              "Entreprenör",
              "SEO expert",
              "Webbutvecklare"
            ],
            "description": "Alvin Scherdin är entreprenör, SEO expert och webbutvecklare. Under sina år som entreprenör har han grundat och byggt över 30 webbplatser, främst för den svenska marknaden. Genom sin resa har han skrivit över 1000 artiklar och utvecklat verktyg på internet som hjälper användare med mycket nischade problem. Detta gör han genom noggrann webbutveckling, pixelperfekt design och SEO-arbete som få kan mäta sig med.",
            "url": "http://alvinscherdin.se", 
            "sameAs": [
              "https://www.linkedin.com/in/alvin-scherdin-4894682b6/",
              "https://www.instagram.com/_alvin_es",
              "https://x.com/DunderMannen",
              "https://medium.com/@alvinss",
              "https://www.facebook.com/alvin.scherdin",
              "https://dofterian.se/author/admin/",
              "https://tryggt.nu/skribent/alvin/",
              "https://www.crunchbase.com/person/alvin-scherdin"
            ],
            "image": "http://alvinscherdin.se/img/alvin.webp",
            "email": "mailto:alvin@scherdinmedia.se"
          }
          `}
        </script>
      </Helmet>
      
      <img src={alvin} alt="Alvin Scherdin" width="150px" height="auto"></img>
      <h1>Alvin <span style={{ fontWeight: 500 }}>Scherdin</span>
      <span class="heading">- Entreprenör, SEO expert, webbutvecklare</span>
      </h1>

      <div class="icons">
        <a href="https://twitter.com/DunderMannen" target="_blank" rel="nofollow noreferrer">
          <div class="icon">
            <img src={twitter} alt="Twitter icon"></img>
          </div>
        </a>

        
        <a href="mailto:alvin@scherdinmedia.se" target="_blank" rel="nofollow noreferrer">
          <div class="icon">
            <img src={mail} alt="Mail icon"></img>
          </div>
        </a>
        

        <a href={cv} download>
        <div class="icon">
            <img src={cvIcon} alt="CV icon"></img>
          </div>
        </a>
      </div>

      <ul>
        <li>Grundare och VD för Scherdin Media</li>
        <li>Byggt hemsidor med hög organisk trafik, med inriktning mot Sverige</li>
        <li>Är webbutvecklare för startup-företaget InviteToDo</li>
        <li>Skrivit över 1000 artiklar och publicerat på nätet</li>
        <li>Utvecklat mängder av webbtjänster, hemsidor till tredjeparter och SaaS-plattformar.</li>
      </ul>

      <h2>Vem är Alvin Scherdin?</h2>
      <p>
        Alvin Scherdin är entreprenör, SEO expert och webbutvecklare. Under sina år som 
        entreprenör har han grundat och byggt över 30 webbplatser, främst för den svenska marknaden.
         Genom sin resa har han skrivit över 1000 artiklar och utvecklat verktyg på internet som 
         hjälper användare med mycket nischade problem. Detta gör han genom noggrann webbutveckling, 
        pixelperfekt design och SEO-arbete som få kan mäta sig med.
      </p>

      <h3>Alvin Scherdin är grundare och VD för Scherdin Media</h3>
      <p>
        Alvin Scherdin grundade år 2020 <a href="https://scherdinmedia.se">Scherdin Media</a>. Organisationen tillhandahåller och utvecklar webbtjänster som skapar tillväxt. Under åren har 
        Scherdin Media byggt över 30 lönsamma webbtjänster och hemsidor. Alla hemsidor har individuella content-strategier och annonsörer för att maximera 
        lönsamhet.
      </p>

      <h3>Alvin Scherdin har byggt hemsidor med hög organisk trafik</h3>
      <p>
        Med åren har han utvecklat sina kunskaper inom sökmotoroptimering och en portfölj av hemsidor som genererar hög organisk trafik, främst på den Svenska marknaden.
        Han ser till att hänga med i svängarna i den konstant förändrande världen av SEO genom kontinuerlig nyfikenhet och att inte vara rädd för förändringar.
        <br></br><br></br>
        Bland annat byggde han år 2023 en hemsida som gick från 0 till 30 000 organiska besökare per månad med endast 3 månaders arbete. 
      </p>

      <h3>Alvin Scherdin är webbutvecklare för startup-företaget InviteToDo</h3>
      <p>
        Webbutvecklingsarbetet med InviteToDo började år 2021 efter att man såg ett problem hos många organisationer - att lösa problem! Den digitala plattformen har nu tagit form och de första 
        användarna har börjat anlända. Tillsammans med de andra medarbetarna i företaget har den kompletta designen, content-strukturen och front end utveckling gjorts av honom. Ett litet team 
        behöver inte betyda ett ineffektivt team!
      </p>

      <h3>Alvin Scherdin har skrivit över 1000 artiklar</h3>
      <p>
        Med SEO arbete kommer även utformning av content. Under åren har Alvin skrivit över 1000 artiklar på egen hand och har på så sätt
        bildat både erfarenhet och extrem spetskompetens inom webbtexter. 
        <br></br><br></br>
        Huvudfokus när det kommer till content ser Alvin är noggrannhet, autenticitet och enkelhet. Alla texter på hans hemsidor är lätta att förstå, enkla att navigera och utformade med högsta möjliga integritet för att garantera äkta information.
      </p>

      <h3>Webbutvecklingsprojekt av Alvin Scherdin</h3>
      <p>
        Genom åren har Alvin Scherdin utvecklat mängder av webbtjänster, hemsidor till tredjeparter och SaaS-plattformar. Detta med hjälp av olika ramverk såsom React, Django och Ejs. I andra fall har han även byggt hemsidor med hjälp
        av plattformar som Wordpress, Shopify och Joomla.
      </p>
      <div class="projects">
        <div class="project">
        <a href="https://maxos-kungsholmen.se" rel="nofollow noreferrer" target="_blank"><img src={maxos} width="100%" height="auto" alt="maxos"></img></a>

          <h4>HTML/CSS + JS</h4>

          <p>
            En simpel, men snygg
            sajt till en lokal restaurang
            på Kungsholmen. 
            <br></br><br></br>
            Prio var responsiv design
            med mycket bilder. Det sattes även ihop ett simpelt cateringsystem.
          </p>
        </div>
        
        <div class="project">
        <a href="https://invitetodo.com" rel="nofollow noreferrer" target="_blank"><img src={itd} width="100%" height="auto" alt="InviteToDo"></img></a>

          <h4>DJANGO</h4>

          <p>
            SaaS-lösning lämpad för att företag och kommuner ska ta sina idéer till verklighet.
            <br></br><br></br>
            Prio var en skalbar design som gör det lätt för vidareutveckling.
          </p>
        </div>

        <div class="project">
          <a href="https://bäst-vpn.nu" rel="nofollow noreferrer" target="_blank"><img src={vpn} width="100%" height="auto" alt="VPN sajt"></img></a>

          <h4>HTML/CSS + JS</h4>

          <p>
            Simpel sajt med fokus på skalbarhet i form av många undersidor.
            <br></br><br></br>
            Prio var reponsivitet, lättillgänglighet och enkla "call-to-actions". Sajtens hastighet
             är också optimerad för bästa användarupplevelse.
          </p>
        </div>
      </div>

      <h3>Alvin Scherdin har handlat med exklusiva skor och kläder</h3>
      <p>
        Tidigt på den entreprenöriska resan var "reselling" av exklusiva skor och kläder i fokus. Detta var en långfristig affärsverksamhet som han drev från år 2018 till 2021. 
        Med hjälp av detta intresse lärde han sig även grunderna i social marknadsföring, front-end utveckling och automatiseringsprogram såsom Selenium och Requests.
      </p>

      <div class="footer">
        <p>info[at]scherdinmedia.se</p>
      </div>
    </div>
  );
}

export default App;
